//LINKS AND BUTTONS
.btn-wrap {
  width: 100%;
  @include flexbox(row, nowrap, flex-end);
  margin: 10px 0;
  @include flex-align(center);
}
.btn-wrap-grp-right {
  text-align: right;
  @include right-align-block;
}
.btn-right {
  text-align: center;
  padding: 5px 8px;
  cursor: pointer;
  border: 0;
  @include right-align-block;
  &:focus {
    border-bottom: 2px solid $color-primary;
    outline: none;
  }
}

.btn-disabled {
  background-color: #e5e5e5 !important;
  text-align: center;
  color: #fff !important;
  padding: 5px 8px;
  border-radius: 3px;
  letter-spacing: 0.7px;
  box-sizing: border-box;
  font-size: 0.9rem;
  cursor: pointer;
}

.btn-link {
  @extend a;
  font-size: 0.9em;
}
.btn-link-alarm {
  @extend .btn-link;
  color: $color-bright;
}
.btn-delete {
  border: 0;
  color: $color-bright;
}
.btn-main {
  background-color: $color-primary !important;
  text-align: center;
  color: $white !important;
  padding: 5px 8px;
  font-size: 13px;
  border-radius: 3px;
  letter-spacing: 0.7px;
  box-sizing: border-box;
  font-size: 0.9rem;
  cursor: pointer;
  align-items: center;
  &:hover {
    color: $white !important;
  }
  &:active {
    color: $white !important;
    background-color: $color-primary-dark !important;
  }
  &:focus {
    border-bottom: 2px solid $color-primary !important;
    outline: none;
  }
}
.btn-margin-left {
  margin-left: 10px;
}
.btn-margin-right {
  margin-right: 10px;
}

.btn-second {
  text-align: center;
  color: $color-secondary;
  padding: 5px 8px;
  border: 1px solid $color-secondary-light;
  font-size: 13px;
  &:hover {
    color: $white;
    background: $color-secondary-light;
  }
  &:active {
    color: $white;
    background: $color-secondary-dark;
  }
  &:focus {
    border-bottom: 2px solid $color-primary;
    outline: none;
  }
}
.btn-accent {
  @extend .btn-main;
  background-color: $color-accent-light;
  border: $color-accent-light;
}
.btn-alarm {
  text-align: center;
  color: $color-bright;
  padding: 5px 8px;
  border: 1px solid $color-bright-light;
  font-size: 13px;
  &:hover {
    color: $white;
    background: $color-bright-light;
  }
  &:active {
    color: $white;
    background: $color-bright-dark;
  }
  &:focus {
    border-bottom: 2px solid $color-primary;
    outline: none;
  }
}
.btn__modalx {
  border-radius: 100%;
  background-color: $color-bright;
  padding: 6px;
  border: 0;
  float: right;
}
.btn-save {
  background-color: $color-primary;
  border: 0;
  color: $white;
  padding: 6px 10px !important;
  font-size: 13px;
  &:focus {
    border-bottom: 2px solid $color-primary;
    outline: none;
  }
  &:hover {
    background-color: darken($color-primary, 10%);
    color: $white;
  }
}
.btn-send {
  display: block;
  padding: 6px 30px;
  margin: 10px 0 0 auto;
  color: $color-primary-light;
  background: url(/images/send-mail__icon.png) no-repeat left center;
  cursor: pointer;
  border: 1px solid $color-grayLight;
  &:hover,
  &.hover {
    color: black;
    font-weight: $font-p-bold;
    background: $color-primary-light url(/images/send-mail__icon__white.png)
      no-repeat right center;
  }
  &:active,
  &.active {
    background: $color-accent url(/images/send-mail__icon__white.png) no-repeat
      right center;
    color: $white;
  }
}
.btn-empty {
  @extend .btn-main;
  background-color: transparent;
  border: 1px solid $color-grayLight;
  color: $color-grayDark;
  &:focus {
    border-bottom: 2px solid $color-primary;
    outline: none;
  }
  &:hover {
    border: 1px solid $color-secondary-light;
    color: $color-secondary-light;
  }
  &:active {
    border: 1px solid $color-secondary-dark;
    color: $white;
    background-color: $color-secondary-light;
  }
}
.btn-x {
  border: none !important;
  @include right-align-block;
  font-size: 1.1em;
  font-weight: $font-h-bold;
  padding-left: 10px;
  color: $color-grayDark;
  font-family: $font-h;
  cursor: pointer;
  &:focus {
    border-bottom: 2px solid $color-primary;
    outline: none;
  }
  &:hover {
    color: $color-primary-light !important;
  }
}
.btn-saving {
  background-color: $white;
  color: $color-secondary !important;
  @include flexbox-sb;
  @include flex-align(center);
  img {
    display: inline;
    margin: 0 6px;
  }
  span {
    display: inline;
    margin-right: 8px;
  }
}
.btn-skip {
  color: $color-grayDark;
  margin-right: 15px;
  border: 0 !important;
  &:hover {
    color: $color-accent !important;
  }
}
.btn-cancel {
  border: 0;
  margin-right: 15px;
}
.btn-drag-n-drop {
  padding: 10px;
  border: 0;
  background: url('/images/icon_drag-drop.png') no-repeat center center;
}
.read-more-button {
  color: $color-accent;
  cursor: pointer;
  font-size: 0.9em;
  margin-top: 10px;
  &:after {
    content: '...';
  }
}
.btn-send {
  @include btn-send;
}
//Top Bar
.top-bar {
  margin-bottom: 1em;
  button {
    color: $color-grayDark;
    font-size: 1em;
    border: 0;
    border-radius: 0;
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
  &__selected {
    color: $color-secondary !important;
    border-bottom: 2px solid $color-secondary !important;
  }
}
//Specialized buttons
.edit-pen {
  //span {inside button}
  width: 20px;
  height: 15px;
  margin: 0 0 0 5px;
  cursor: pointer;
  background-image: url(/images/edit-pen--accent.png);
  display: inline-block;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-color: transparent;
  &__btn {
    //button {contatiner wrap} + class &__name:hover::before {content:'edit name'}
    display: inline-block;
    text-align: right;
    right: 0;
    padding: 0;
    margin: 0 0 0 auto;
    border: 0 !important;
    @include flex-align(center);
    background-color: transparent;
    &:active {
      background-color: transparent;
    }
    &:hover {
      &::before {
        color: $color-accent;
      }
      &::after {
        color: $color-accent;
      }
    }
  }
}
.edit-pen-light {
  @extend .edit-pen;
  background-image: url(/images/edit-pen--accent-light.png);
}
.plus-sign {
  //span {inside button}
  @extend .plusSign;
  width: 12px !important;
  height: 12px !important;
  margin: 0 11px 0 5px !important;
  background-color: transparent;
  &__btn {
    //button {contatiner wrap} + class &__name:hover::before {content:'edit name'}
    display: inline-block;
    text-align: right;
    right: 0;
    padding: 0;
    margin: 0 0 0 auto;
    border: 0;
    @include flex-align(center);
    background-color: transparent;
    &:active {
      background-color: transparent;
    }
    &:hover {
      &::before {
        color: $color-accent;
      }
    }
  }
}
//RADIO AND CHECKBOXES
/*for input (CHECKBOXES + RADIO ONLY)*/
.clickable__input {
  display: none;
  &:checked ~ .clickable__check-round {
    background: url('/images/icon_check_white.png') $color-accent-light center
      center no-repeat;
    background-size: 10px auto;
  }
  &:checked ~ .clickable__radio-round {
    background: $color-accent-light;
    background-size: 10px auto;
  }
}
/*For label (CHECKBOXES ONLY)*/
.clickable__check-round {
  width: 20px;
  height: 20px;
  min-height: 20px;
  min-width: 20px;
  border-radius: 50%;
  border: 1px solid lighten($color-grayDark, 10%);
  display: block;
}
/*For label (RADIO ONLY)*/
.clickable__radio-round {
  @extend .clickable__check-round;
}
//TOOLTIP
.tooltip-mark {
  cursor: pointer;
  padding: 0 10px;
  color: $color-accent-light;
  &:hover ~ .tooltip {
    display: block;
  }
}
.tooltip {
  display: none;
  position: absolute;
  right: 0;
  top: 15px;
  background: $color-secondary;
  color: $white;
  padding: 15px;
  @include box-shadow(8px, 8px, 8px, -3px, 0, 0, 0, 0.3);
  border-radius: 4px;
  width: 400px;
  line-height: 1.4;
  font-size: 16px;
  z-index: 10;
  a {
    color: $color-accent-light;
  }
  a:hover {
    color: $color-accent;
    border-bottom: 1px solid $color-accent-light;
  }
  &:hover {
    display: block;
  }
}

//PIC ALIGNMENT
.profile-pic {
  object-fit: cover;
  border: 1px solid $color-bg;
}
.profile-pic-round {
  object-fit: cover;
  border: 1px solid $color-bg;
  border-radius: 50%;
}
.logo {
  object-fit: cover;
  border: 1px solid $color-bg;
}
//Person Wrap
.person-wrap {
  @include flexbox(row, nowrap, flex-start);
  @include flex-align(center);
  font-size: 0.9em;
  color: $color-secondary;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  .profile-pic-round {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }
  .profile-name {
    text-align: left;
  }
}
//SEARCH BAR
.search-bar {
  width: 100%;
  @include flexbox-sb;
  background: $white;
  border: 1px solid $color-bg;
  @include flex-align(center);
  border-radius: 10px;
  overflow: hidden;
}
.search-input {
  width: 90%;
  border: 0;
}
.search-btn {
  width: 5%;
  height: 100%;
  padding: 20px;
  border: 0;
  background: url('/images/icon_search.png') no-repeat 80% center;
  background-size: 25px auto;
}
//SHAPES
.exit__x {
  @include right-align-block;
  font-family: $font-h;
  color: $color-secondary;
  padding: 10px;
  text-align: right;
}
.circle {
  @include border-radius(50%);
}
.plusSign {
  position: relative;
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 2px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: $color-accent;
    transition: transform 0.25s ease-out;
    //&:hover {
    //background-color: $color-secondary;
    //}
  }
  /* Vertical line */
  &:before {
    top: 0;
    left: 50%;
    width: 4px;
    height: 100%;
    margin-left: -2px;
  }
  /* horizontal line */
  &:after {
    top: 50%;
    left: 0;
    width: 100%;
    height: 4px;
    margin-top: -2px;
  }
  &--grey {
    &:before {
      background-color: $color-grayDark;
    }
    &:after {
      background-color: $color-grayDark;
    }
  }
}
.triangle__down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid $color-accent;
  display: inline-block;
  margin: 0 5px;
}
.triangle__up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid $color-accent;
  display: inline-block;
  margin: 0 5px;
}
.triangle__downWhite {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid $white;
  display: inline-block;
  &:hover {
    border-bottom: $color-secondary;
  }
}
.triangle__upWhite {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid $white;
  display: inline-block;
  &:hover {
    border-bottom: $color-secondary;
  }
}
.mail {
  display: inline-block;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top: 6px solid $color-grayDark;
  border-right: 11px solid $color-grayLight;
  border-bottom: 6px solid $color-grayLight;
  border-left: 11px solid $color-grayLight;
}
.hexagon {
  width: 6.66666667px;
  height: 10px;
  background: $color-accent;
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }
  &:before {
    left: -2.5px;
    top: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 2.5px solid $color-accent;
  }
  &:after {
    right: -2.5px;
    bottom: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 2.5px solid $color-accent;
  }
}
.hexagon__gray {
  @extend .hexagon;
  background: $color-grayDark;
  &:before {
    @extend .hexagon, :before;
    border-right: 2.5px solid $color-grayDark;
  }
  &:after {
    @extend .hexagon, :after;
    border-left: 2.5px solid $color-grayDark;
  }
}
.checkbox {
  border: 1px solid $color-grayDark;
  height: 1em;
  width: 1em;
  display: inline-block;
  margin-right: 5px;
}
input[type='checkbox'] {
  &:checked + .checkbox {
    background-image: url('/images/icon_check.png');
    background-size: 0.9em;
    background-repeat: no-repeat;
    background-position: center;
  }
}

//COLORS
.backg__accent {
  background-color: $color-accent;
}
.backg__primary {
  background-color: $color-primary;
}
.backg__secondary {
  background-color: $color-secondary;
}
.backg__bg {
  background-color: $color-bg;
  min-height: $full-height;
}
.backg__white {
  background-color: $white;
}
.color__secondary {
  color: $color-secondary;
}
.color__accent {
  color: $color-accent;
}

//FONT STYLES
.bold {
  font-weight: 600;
}
.italic {
  font-style: italic;
}
.modal-title-h3 {
  font-weight: $font-h-bold;
  text-transform: uppercase;
}
.modal-subtitle {
  color: $color-primary-light;
  text-transform: uppercase;
}
.modal-note {
  color: $color-graySubDark;
  font-style: italic;
  margin-bottom: 15px;
  display: block;
  font-size: 0.9em;
}

//MESSAGES
.messageRed {
  color: $color-bright;
}
.messageGray {
  color: $color-grayDark;
}
.inline-error {
  color: $color-bright;
  font-size: 0.9em;
}

.inline-warning {
  color: $color-warning;
  font-size: 0.9em;
}
//Top Header
.top-wayfinder {
  @media (min-width: $break-xl) {
    background: inherit;
    width: 100%;
    position: sticky;
    top: 34px;
    padding: 10px 0;
    z-index: 2;
  }
}
