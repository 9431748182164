@import '../../../../css/utilities/mixin';
@import '../../../../css/utilities/variables';

.header {
  margin: 0;
  z-index: 10;
  grid-area: header;
}

.topNav {
  &__icon {
    width: auto;
    max-height: 16px;
    margin: 0 3px;
    @media (max-width: $break-m) {
      width: auto;
      height: 20px;
      margin: 0 auto !important;
    }
    &__new {
      height: 16px;
      width: auto;
      @media (max-width: $break-m) {
        margin: 0 auto !important;
        height: 20px;
      }
    }
    &__avatar-wrap {
      display: inline-block;
      margin: 0 3px;
      @media (max-width: $break-m) {
        margin: 0 auto !important;
      }
      img {
        width: auto;
        height: 16px;
        object-fit: cover;
        @media (max-width: $break-m) {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.public {
  @include border-box;
  padding: 0 2rem;
  background-color: $white;
  display: flex;
  flex-direction: column;
  margin: 0;
  flex: 1;

  @media screen and (max-width: $break-m) {
    padding: 0 2.5%;
  }

  &__wrap {
    @include flexbox-sb;
    align-items: center;
    border-top: 1px solid $color-bg;
    &--left {
      display: flex;
      align-items: center;
    }
  }
  &__content {
    @include flexbox-sb;
    @include flex-align(center);
  }
  &__linkWrap {
    @include flexbox-sb;
    @include flex-align(center);
    color: $color-secondary;
    font-size: 0.9em;

    &__langWrap {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &__lang {
      @include flexbox-sb;
      align-items: center;
      padding-right: 1em;
      margin-right: 1em;
      position: relative;
      border-right: 0px;

      &:before {
        content: '';
        background: url('/images/icon_world_dark.png') no-repeat center left;
        background-size: 15px 15px;
        padding: 8px 15px 8px 6px;
      }
    }
    &__contact {
      @include flexbox-sb;
      align-items: center;
      a {
        color: $color-secondary;
        margin-left: 6px;
      }
    }
  }
  &__logo {
    width: 170px;
    padding: 10px;
    padding-left: 0;
    @media (min-width: $break-l) {
      width: 170px;
      margin-right: 2rem;
    }
  }
  &__link {
    display: none !important;
    @media (min-width: $break-m) {
      display: inline-block !important;
      font-size: 0.9em;
      margin-left: 2rem;
      a {
        color: $color-secondary-dark;
      }
    }
  }
  &__drpdwn {
    display: inline-block;
    position: relative;
    margin-right: 10px;
    @media (min-width: $break-m) {
      display: none;
      &--li {
        display: none !important;
      }
    }
    &:hover .public__drpdwn__content {
      display: block;
    }
    &__content {
      display: none;
      position: absolute;
      background-color: $white;
      min-width: 170px;
      @include box-shadow(0px, 8px, 16px, 0px, 0, 0, 0, 0.2);
      z-index: 4;
      margin-top: 2px;
      right: 0;
      &__link {
        color: $color-secondary-light;
        &:hover {
          background-color: $color-bg;
          color: $color-secondary;
        }
      }
      & .public__drpdwn__content__link {
        padding: 8px 16px;
        display: block;
      }
    }
  }
  &__btn-wrap {
    z-index: 5;
    button {
      font-family: $font-p;
      font-size: 0.9em;
      padding: 4px 8px;
      @media (max-width: $break-s) {
        padding: 5px 8px;
        font-size: 0.85em;
      }
    }
  }
  &__log-btn {
    border-color: $color-accent-light;
    color: $color-accent-light;
    margin-right: 1rem;
    padding: 4px 10px;
    &:hover {
      background-color: $color-bg;
    }
  }
  &__signup-btn {
    background-color: $color-accent-light;
    border-color: $color-accent-light;
    color: $white;
    margin-left: -1px;
    padding: 4px 10px;
    &:hover {
      color: $white;
      background-color: $color-accent-dark;
      border-color: $color-accent-dark;
    }
  }
  &__goto-btn {
    background-color: $color-accent-light;
    border-color: $color-accent-light;
    color: $white;
    &:hover {
      color: $white;
      background-color: $color-accent-dark;
      border-color: $color-accent-dark;
    }
    @media (max-width: $break-s) {
      display: none;
    }
  }
  &__menu {
    color: $color-secondary-light;
    cursor: pointer;
    &:before {
      content: '';
      padding: 15px;
      background: url('/images/hp_top_menu.png') no-repeat center center;
      background-size: 15px 15px;
    }
  }
  .language-picker {
    &__more {
      width: 100%;
      height: 100%;
      padding: 0 5px;
      color: transparent;
      &:hover {
        background-color: transparent;
      }
    }
    &__side {
      position: absolute;
      background-color: $white;
      width: 130px;
      z-index: 1;
    }
    &__link {
      color: $color-secondary;
      @include flexbox(row, nowrap, center);
      padding: 10px 18px;
      position: relative;
      font-size: 0.9em;
      &:hover {
        background-color: $color-secondary;
        color: $white;
      }
      &--selected {
        @extend .language-picker__link;
        position: relative;
        color: $color-grayDark;
        &:before {
          content: '';
          background: url('/images/icon_check.png') no-repeat center left;
          background-size: 10px 10px;
          padding: 5px;
          position: absolute;
          left: 20px;
          top: 12px;
        }
        &:hover {
          background: $white;
          color: $color-grayDark;
        }
      }
    }
  }
}

.homepageNav {
  height: 70px;
  display: inline-block;
  background-color: #28444d;
  width: 100vw;
  max-width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;
  @media (max-width: $break-s) {
    height: 40px;
  }
  &__link {
    display: none !important;
    @media (min-width: $break-l) {
      display: inline-block !important;
      a {
        color: $white !important;
      }
    }
  }
  &__wrap {
    @extend .public__wrap;
    margin: 10px auto 0;
    @media (max-width: $break-m) {
      margin: 10px 10px 0;
    }
  }
  &__logo {
    width: 100px;
    padding: 10px;
    display: none;
    @media (min-width: $break-l) {
      width: 170px;
      margin-right: 50px;
    }
    @media (min-width: $break-m) {
      display: block;
    }
    @media (max-width: $break-s) {
      display: none !important;
    }
  }
  &__btn-wrap {
    z-index: 5;
    position: relative;
    button {
      font-family: $font-p;
      font-size: 1em;
      padding: 10px 15px;
      @media (max-width: $break-s) {
        padding: 5px 8px;
        font-size: 0.85em;
      }
    }
    .language-picker {
      &__more {
        color: $white;
        width: 25px;
        padding: 0 5px;
        overflow: hidden;
        &:before {
          content: '';
          background: url('/images/icon_world_white.png') no-repeat center left;
          background-size: 15px 15px;
          padding: 8px 18px 8px 6px;
        }
      }
      &__side {
        position: absolute;
        background-color: $white;
        width: 130px;
      }
      &__link {
        color: $color-secondary;
        @include flexbox(row, nowrap, center);
        padding: 10px 18px;
        position: relative;
        font-size: 0.9em;
        &:hover {
          background-color: $color-secondary;
          color: $white;
        }
        &--selected {
          @extend .language-picker__link;
          position: relative;
          color: $color-grayDark;
          &:before {
            content: '';
            background: url('/images/icon_check.png') no-repeat center left;
            background-size: 10px 10px;
            padding: 5px;
            position: absolute;
            left: 20px;
            top: 12px;
          }
          &:hover {
            background: $white;
            color: $color-grayDark;
          }
        }
      }
    }
  }
  &__log-btn {
    border-color: $white;
    color: $white;
    margin-right: 1rem;
    &:hover {
      background-color: $color-bg;
    }
    @media (max-width: $break-s) {
      font-size: 0.8em !important;
      padding: 3px !important;
    }
  }
  &__signup-btn {
    background-color: $color-accent-light;
    border-color: $color-accent-light;
    color: $white;
    margin-left: -1px;
    &:hover {
      color: $white;
      background-color: $color-accent-dark;
      border-color: $color-accent-dark;
    }
    @media (max-width: $break-s) {
      font-size: 0.8em !important;
      padding: 3px !important;
    }
  }
  &__goto-btn {
    background-color: $color-accent-light;
    border-color: $color-accent-light;
    color: $white;
    &:hover {
      color: $white;
      background-color: $color-accent-dark;
      border-color: $color-accent-dark;
    }
    @media (max-width: $break-s) {
      display: none;
    }
  }
  &__menu {
    color: $white;
    display: inline-block;
    margin: -10px 0 0 5px;
    cursor: pointer;
  }
}

.inApp {
  @include border-box;
  height: 35px;
  width: 100%;
  border-bottom: 1px solid $color-bg;
  background-color: $white;
  @media (max-width: $break-m) {
    @include flexbox(row-reverse, nowrap, space-between);
    border-top: 2px solid $color-bg;
    height: 50px;
    top: auto;
    bottom: 0;
    position: fixed;
    background: $white;
    z-index: 20;
  }
  span {
    cursor: pointer;
  }
  &__link {
    color: $color-secondary-light;
    font-size: 0.9rem;
    margin: 0 6px;
    display: block;
    @media (max-width: $break-m) {
      flex: 1 1 auto;
      text-align: center;
    }
    &--menu {
      @extend .inApp__link;
      &:before {
        background: url('/images/top_menu.png') no-repeat center center;
        padding: 10px;
        content: '';
        background-size: 15px;
        @media (max-width: $break-m) {
          display: block;
          padding: 12px 10px 10px;
          background-position: top center;
          background-size: 20px;
        }
      }
      @media (max-width: $break-m) {
        span {
          display: none;
        }
      }
    }
    &--search {
      @extend .inApp__link;
      margin-right: auto;
      margin-left: 0;
      &:before {
        background: url('/images/top_search.png') no-repeat center center;
        padding: 10px;
        content: '';
        background-size: 15px;
        @media (max-width: $break-m) {
          display: block;
          padding: 12px 10px 10px;
          background-position: top center;
          background-size: 20px;
        }
      }
      @media (max-width: $break-m) {
        span {
          display: none;
        }
      }
    }
    &--admin {
      @extend .inApp__link;
      &:before {
        background: url('/images/logo_mark.png') no-repeat center center;
        padding: 10px;
        content: '';
        background-size: 15px;
        @media (max-width: $break-m) {
          display: block;
          padding: 12px 10px 10px;
          background-position: top center;
          background-size: 20px;
        }
      }
      @media (max-width: $break-m) {
        span {
          display: none;
        }
      }
    }
    &--notification {
      @extend .inApp__link;
      &:before {
        background: url('/images/top_notify.png') no-repeat center center;
        padding: 10px;
        content: '';
        background-size: 15px;
        @media (max-width: $break-m) {
          display: block;
          padding: 12px 10px 10px;
          background-position: top center;
          background-size: 20px;
        }
      }
      @media (max-width: $break-m) {
        span {
          display: none;
        }
      }
    }
    &--notification-new {
      @extend .inApp__link;
      &:before {
        background: url('/images/top_notify-new.png') no-repeat center center;
        padding: 10px;
        content: '';
        background-size: 15px;
        margin-right: 2px;
        @media (max-width: $break-m) {
          display: block;
          padding: 12px 10px 10px;
          background-position: top center;
          background-size: 20px;
        }
      }
      @media (max-width: $break-m) {
        span {
          display: none;
        }
      }
    }
    &--profile {
      @extend .inApp__link;
      @include flexbox(row, nowrap, flex-start);
      @include flex-align(center);
      @media (max-width: $break-m) {
        @include flex-direction(column);
      }
      img {
        height: 18px;
        width: 18px;
        margin-right: 5px;
        @media (max-width: $break-m) {
          height: 25px;
          width: 25px;
          margin-right: 0;
        }
        @media (min-width: $break-m) {
          border: 0;
        }
      }
      span {
        max-width: 70px;
        overflow: hidden;
        max-height: 15px;
        @media (max-width: $break-m) {
          margin-top: 3px;
          max-width: 50px;
          display: none;
        }
      }
    }
    &--settings {
      @extend .inApp__link;
      @media (max-width: $break-m) {
        display: none;
      }
      &:before {
        background: url('/images/top_settings.png') no-repeat center center;
        padding: 10px;
        content: '';
        background-size: 18px;
        margin-right: 2px;
      }
    }
    &--help {
      @extend .inApp__link;
      @media (max-width: $break-m) {
        display: none;
      }
      &:before {
        background: url('/images/top_help.png') no-repeat center center;
        padding: 10px;
        content: '';
        background-size: 18px;
        margin-right: 2px;
      }
    }
    &--more {
      @extend .inApp__link;
      cursor: pointer;
      margin-right: 10px;
      @media (max-width: $break-m) {
        display: none;
      }
      &:before {
        background: url('/images/top_dropdown.png') no-repeat center center;
        padding: 10px;
        content: '';
        background-size: 10px;
      }
      &:hover ~ .inApp__drpdwn__content {
        display: block;
      }
    }
  }
  &__drpdwn {
    position: relative;
  }
  &__drpdwn__content {
    display: none;
    position: absolute;
    background-color: $white;
    @include box-shadow(0px, 8px, 16px, 0px, 0, 0, 0, 0.2);
    z-index: 10;
    text-align: center;
    right: 10px;
    font-size: 0.9em;
    width: 120px;
    &:hover {
      display: block;
    }
    &__link-logout {
      color: $color-secondary-light;
      @include flexbox(row, nowrap, center);
      padding: 10px 18px;
      background-color: $color-bg;
      width: 100%;
      border-radius: 0;
      &:hover {
        background-color: $color-secondary;
        color: $white;
      }
    }
    .language-picker {
      &__link {
        color: $color-secondary-light;
        @include flexbox(row, nowrap, center);
        padding: 10px 18px;
        width: 100%;
        border-radius: 0;
        border: 0;
        position: relative;
        margin: 0;
        &:hover {
          background-color: $color-secondary;
          color: $white;
        }
        &--selected {
          @extend .language-picker__link;
          position: relative;
          color: $color-grayDark;
          &:before {
            content: '';
            background: url('/images/icon_check.png') no-repeat center left;
            background-size: 10px 10px;
            padding: 5px;
            position: absolute;
            left: 20px;
            top: 12px;
          }
        }
      }
      &__more {
        color: $color-secondary-light;
        @include flexbox(row, nowrap, center);
        padding: 10px 18px 10px 10px;
        width: 100%;
        border-radius: 0;
        font-size: 0.8em;
        letter-spacing: 0.7px;
        border: 0.5px solid $color-grayLight;
        border-bottom: 0;
        cursor: pointer;
        position: relative;
        &:before {
          content: '';
          background: url('/images/icon_world_dark.png') no-repeat center left;
          background-size: 15px 15px;
          padding: 8px 18px 8px 6px;
        }
        &:hover {
          background-color: $color-secondary;
          color: $white;
          ~ .language-picker__side {
            display: block;
          }
          &:before {
            content: '';
            background: url('/images/icon_world_white.png') no-repeat center
              left;
            background-size: 15px 15px;
            padding: 8px 18px 8px 6px;
          }
        }
      }
      &__side {
        display: none;
        position: inline;
        background-color: $white;
        &:hover {
          display: block;
        }
      }
    }
  }
}

.main_nav_menu {
  display: flex;
  font-size: 14.4px;

  li {
    margin-right: 1.75rem;
    @media screen and (max-width: $break-l) {
      margin-right: 1rem;
    }
  }
  a {
    color: #1b2526;
  }
  @media screen and (max-width: $break-m) {
    display: none;
  }
}

.hp__fold__button {
  color: #fff;
  background-color: #fea627;
  border-radius: 3px;
  padding: 8px 10px;
  border: none;
  font-size: 14.4px;

  a,
  a:hover,
  a:focus {
    color: #fff;
  }

  @media screen and (max-width: $break-m) {
    display: none;
  }
}

.public__request-demo-btn-top {
  background-color: #fea627;
  border-radius: 3px;
  margin-right: 1rem;
  font-size: 0.8rem;
  padding: 4px 10px;
  border: none;

  a,
  a:focus,
  a:hover {
    color: #fff;
  }

  @media screen and (min-width: $break-m) {
    display: none;
  }
}
