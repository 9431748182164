@import '../../../css/utilities/_mixin.scss';
@import '../../../css/utilities/_variables.scss';

.sign-up__avatar-upload {
  .flex-container {
    @include flexbox(row, wrap, space-around);
    @include flex-align(center);
    @media (max-width: $break-m) {
      @include flexbox(column, wrap, flex-start);
      @include flex-align(flex-start);
    }
    h4 {
      text-transform: uppercase;
      font-size: 0.8em;
      line-height: 100%;
      margin-bottom: -10px;
    }
    h1 {
      font-size: 3em;
      color: $color-accent;
    }
  }
  .image-container {
    margin: 2em 1px 2em 4em;
    width: 250px;
    height: 250px;
    overflow: hidden;
    @include flex;
    @include flex-align(center);
    @include flex-justify(center);
    @media (max-width: $break-m) {
      margin: 2em auto 2em;
    }
    img {
      object-fit: cover;
      margin: auto auto;
      min-height: 250px;
      min-width: 250px;
      display: block;
      position: relative;
    }
  }
  .choose-btn {
    border-radius: 0;
    padding: 8px 10px;
    color: white;
    background-color: $color-accent;
  }
  .save-btn {
    padding: 8px 10px;
    background-color: $color-primary;
    color: $white;
    border: 0;
    &:disabled {
      background-color: $color-grayLight;
      color: $color-grayDark;
    }
  }
  input#picture {
    display: none;
  }
}
