@import 'css/utilities/_mixin.scss';
@import 'css/utilities/_variables.scss';

.noPermission {
  @include vertical-hor-align;
  text-align: center;
  img {
    height: 150px;
    width: auto;
  }
}
