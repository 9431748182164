@import '../../css/utilities/_mixin.scss';
@import '../../css/utilities/_variables.scss';

@mixin contentInfoWrap {
  max-width: 100%;
  width: calc(100% - 40px);
  padding: 20px;
  display: block;
  overflow: auto;
  position: relative;
  margin: 0 auto;
  @include word-wrap;
  @media (min-width: $break-m) {
    max-width: 555px;
  }
  @media (min-width: $break-l) {
    max-width: 672px;
  }
  @media (min-width: $break-xl) {
    max-width: 815px;
  }
  @media (min-width: 1500px) {
    max-width: 1100px;
  }
  @media (min-width: 1800px) {
    max-width: 1300px;
  }
}
//shared elements
.userProfile {
  min-height: calc(100vh - 310px);
  max-width: 100%;
  margin: 0;
  &__wrap {
    @include flexbox(row, nowrap, flex-start);
    @media (max-width: $break-s) {
      @include flex-direction(column);
    }
  }
  .edit-pen__social__lnk {
    color: $color-accent;
    &__active {
      background-color: transparent;
    }
  }
  &__edit {
    @include flexbox-sb;
    @include flex-align(center);
    width: 100%;
    cursor: pointer;
    margin: 1em 0 2em;
    &--small {
      @media (max-width: $break-s) {
        margin: 0;
        margin-bottom: -15px;
      }
    }
    .edit-pen {
      text-align: right;
      &__about {
        &:hover::before {
          content: 'edit about';
        }
      }
      &__experience {
        &:hover::before {
          content: 'add experience';
        }
      }
      &__experience-edit {
        margin: -15px 0 0;
        &:hover::before {
          content: 'edit experience';
        }
      }
    }
  }
  &__save {
    @extend .userProfile__edit;
    margin-top: 5px;
    @include right-align-block;
    &-btn {
      color: $color-accent;
      border-color: $color-accent;
      &:hover {
        background-color: $color-accent;
        color: $white;
      }
    }
  }
}

//Progress Bar
.profile-prog {
  margin: 0;
  padding: 5px 5px;
  background-color: $color-bg;
  &__wrap {
    @include inner__layout--content;
  }
  &__cont {
    @include flexbox(row, nowrap, flex-start);
    @include flex-align(center);
  }
  &__bar {
    background-color: $white;
    width: 200px;
    height: 15px;
    &-fill {
      height: 15px;
      background-color: $color-accent;
    }
  }
  &__percent {
    margin: 10px 30px 10px 10px;
  }
  &__nudge {
    margin: 10px 0;
  }
}

// Profile Visibility
.visibility {
  margin: 0;
  color: $white;
  &__wrap {
    overflow: visible;
    -ms-word-break: normal;
    -ms-word-wrap: normal;
    -webkit-word-break: keep-all;
    -webkit-word-wrap: keep-all;
    word-wrap: normal;
    word-break: keep-all;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
    padding-top: 0 !important;
  }
  &__cont {
    @include flexbox(row, wrap, space-between);
    @include flex-align(center);
    position: relative;
    border-top: 1px solid $color-grayDark;
    @media (max-width: $break-s) {
      border: 0;
    }
  }
  &__title__wrap {
    @include flexbox(row, nowrap, center);
    width: 100%;
    margin-top: 5px;
  }
  &__title {
    display: block;
    margin-bottom: 5px;
    font-size: 0.9em;
  }
  &__subtitle {
    font-style: italic;
    color: $color-grayDark;
    font-size: 0.8em;
  }
  &__selection {
    @include flexbox-sb;
    @include flex-align(center);
    width: 100%;
    justify-content: center;
  }
  &__toggle {
    @include flexbox-sb;
    width: 45px;
    height: 29px;
    background-color: $color-grayDark;
    &:before {
      left: -8px;
      border-top: 14.5px solid transparent;
      border-bottom: 14.5px solid transparent;
      border-right: 8.37px solid $color-grayDark;
    }
    &:after {
      right: -8px;
      border-top: 14.5px solid transparent;
      border-bottom: 14.5px solid transparent;
      border-left: 8.37px solid $color-grayDark;
    }
    .hexagon {
      min-width: 14.43px;
      height: 25px;
      display: inline-block;
      background: $white;
      top: 1.8px;
      z-index: 2;
      &:before {
        left: -7px;
        border-top: 12.5px solid transparent;
        border-bottom: 12.5px solid transparent;
        border-right: 7.22px solid $white;
      }
      &:after {
        right: -7px;
        border-top: 12.5px solid transparent;
        border-bottom: 12.5px solid transparent;
        border-left: 7.22px solid $white;
      }
    }
    @media (min-width: $break-m) {
      width: 30px;
      height: 20px;
      &:before {
        left: -8px;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 9px solid $color-grayDark;
      }
      &:after {
        right: -4px;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 4.185px solid $color-grayDark;
      }
      .hexagon {
        min-width: 11px;
        height: 20px;
        display: inline-block;
        background: $white;
        top: 0px;
        z-index: 2;
        &:before {
          left: -7px;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 7.22px solid $white;
        }
        &:after {
          right: -7px;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 7.22px solid $white;
        }
      }
    }
  }
  &__label {
    top: 5px;
    display: block;
    position: absolute;
    width: 50px;
    color: $color-grayLight;
    @media (min-width: $break-m) {
      width: 40px;
    }
    &__off {
      left: 50px;
    }
    &__on {
      display: none;
    }
  }
  &__form {
    min-width: 120px;
    width: fit-content;
    @include flexbox(row, nowrap, center);
    @media (max-width: $break-m) {
      width: 100%;
    }
    input[type='checkbox'] {
      visibility: hidden;
      width: 0;
      height: 0;
    }
    label {
      font-family: $font-h;
      input:checked + .visibility__toggle {
        background-color: $color-accent;
        &:before {
          border-right: 8.37px solid $color-accent;
        }
        &:after {
          border-left: 8.37px solid $color-accent;
        }
        .visibility__toggle__on {
          left: 30px;
          @media (min-width: $break-m) {
            left: 25px;
          }
        }
        .visibility__label__on {
          display: block;
          left: 50px;
        }
        .visibility__label__off {
          display: none;
        }
      }
    }
  }
  &__learn-more {
    cursor: pointer;
    &:hover .visibility__learn-more__cont {
      display: block;
    }
    &__mark {
      color: $color-accent;
    }
    &__cont {
      position: absolute;
      width: 300px;
      background-color: $white;
      color: $color-secondary;
      border: 2px solid $color-bg;
      padding: 10px;
      z-index: 3;
      display: none;
      @media (max-width: $break-s) {
        left: 50%;
        margin-left: -125px;
        width: 250px;
      }
    }
  }

  &__toggle {
    &__container {
      display: flex;
      justify-content: center;
      align-items: center;

      label {
        width: 2.1rem;
        height: 1.3rem;
        color: $color-grayDark;
        background-color: currentColor;
        margin: 0.8rem 0;
        position: relative;
        -webkit-tap-highlight-color: transparent;

        &::before,
        &::after {
          content: '';
          border-top: 0.65rem solid transparent;
          border-bottom: 0.65rem solid transparent;
          position: absolute;
          height: 0;
          width: 0;
          top: 0;
        }

        &::before {
          border-right: 0.65rem solid currentColor;
          left: -0.65rem;
        }

        &::after {
          border-left: 0.65rem solid currentColor;
          right: -0.65rem;
        }
      }

      input:checked ~ label {
        color: $color-accent;
      }
    }

    &-btn {
      width: 0.65rem;
      height: 1.3rem;
      margin: 0;
      background-color: white;
      position: relative;
      left: 0;
      z-index: 1;
      transition: left 300ms linear;

      &::before,
      &::after {
        content: '';
        border-top: 0.65rem solid transparent;
        border-bottom: 0.65rem solid transparent;
        position: absolute;
        height: 0;
        width: 0;
      }

      &::before {
        border-right: 0.65rem solid white;
        left: -0.65rem;
      }

      &::after {
        border-left: 0.65rem solid white;
        right: -0.65rem;
      }
    }

    &__label {
      margin-left: 1rem;
    }
  }
}

//

.no_permission {
  height: $full-height;
  background-image: url('/images/empty_profile_permission.png');
  background-repeat: no-repeat;
  background-position: 15% center;
  @include flexbox(column, nowrap, space-around);
  @include flex-align(center);
  &__wrap {
    background-color: transparentize($color-secondary, 0.1);
    color: $white;
    width: 100%;
  }
  &__cont {
    @include contentInfoWrap;
    &__logIn {
      color: $white;
      margin: -10px 0 2em;
      display: inline-block;
      font-style: italic;
      &:hover {
        color: $color-accent-light;
      }
      &:active {
        color: $color-accent-dark;
      }
    }
  }
  h2 {
    font-family: $font-p;
  }
  .link-btn {
    padding: 10px 15px;
    background-color: $color-pop;
    border: 0;
    color: $white;
    font-size: 1.1em;
    display: inline-block;
    margin: 1em 0;
  }
}

// Left Bar

.bioInfo {
  max-width: 250px;
  background-color: $color-secondary;
  min-height: $full-height;
  @media (max-width: $break-s) {
    min-height: auto;
  }
  @media (max-width: $break-s) {
    max-width: 100% !important;
    width: 100%;
  }
  @media (max-width: $break-m) {
    max-width: 200px;
  }
  @media (min-width: $break-l) {
    min-width: 250px;
  }
  &__wrap {
    padding: 10px 20px;
    &--small {
      @media (max-width: $break-s) {
        background-color: $white;
      }
    }
  }
  &__link {
    &:hover {
      color: $color-accent-light !important;
      a:hover {
        color: $color-accent-light !important;
      }
    }
  }
  &__title {
    color: $color-grayDark;
    text-transform: uppercase;
    font-family: $font-h;
    margin: 0;
    @media (max-width: $break-s) {
      color: $color-secondary;
      text-align: center;
      margin-bottom: 15px;
    }
    &--small {
      @media (max-width: $break-s) {
        display: none;
      }
    }
  }
  &__name {
    margin-top: 10px;
    color: $white;
    font-size: 1em;
    @media (max-width: $break-s) {
      display: block;
      text-align: center;
    }
    h2 {
      color: $white;
    }
  }
  &__function {
    margin-top: 1em;
    @media (max-width: $break-s) {
      display: block;
      text-align: center;
    }
  }
  &__purpose {
    margin: 0 0 5px 0;
    color: $white;
    @media (max-width: $break-s) {
      font-size: 0.8em;
    }
  }
  &__city {
    font-size: 0.8em;
    color: $white;
  }
  &__pic {
    max-width: 251px;
    width: 100%;
    cursor: pointer;
    position: relative;
    @media (max-width: $break-s) {
      display: block;
      width: 100%;
      max-width: 200px;
      margin: 20px auto 0;
    }
    &__wrap {
      position: relative;
      text-align: center;
      width: fit-content;
      height: fit-content;
      min-width: 100%;
      @media (max-width: $break-m) {
        max-width: 200px;
      }
      &:hover .bioInfo__pic {
        opacity: 0.3;
      }
      &:hover .bioInfo__pic__edit {
        opacity: 1;
      }
      @media (max-width: $break-s) {
        width: 100%;
        max-width: 100vw;
      }
    }
    &__wrap-public {
      @extend .bioInfo__pic__wrap;
      cursor: auto;
      &:hover .bioInfo__pic {
        opacity: 1;
        cursor: auto;
      }
    }
    &__edit {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 110px;
      text-align: center;
      opacity: 0;
      border-radius: 0;
      background-color: $white;
      color: $color-accent;
      border: 1px solid $color-accent;
      padding: 2px 3px;
      font-family: $font-h;
      font-size: 0.8rem;
      &:hover {
        background-color: $color-accent;
        color: $white;
      }
    }
  }
}

.socialMedia {
  max-width: 100%;
  padding: 10px 0;
  border-top: 1px solid $color-grayDark;
  border-bottom: 1px solid $color-grayDark;
  @media (max-width: $break-s) {
    border: 0;
  }

  &__social-links {
    @include flexbox(row, wrap, flex-start);
    align-items: baseline;
    max-width: 700px;
    @include word-wrap;
    @media (min-width: $break-l) {
      max-width: 240px;
      flex-direction: column;
    }
    @media (max-width: $break-s) {
      margin: 0 auto;
      @include flex-justify(center);
    }
    &__cont {
      @include flexbox(row, nowrap, flex-start);
      width: 100%;
      min-height: 32px;
      margin: 8px 0;
      @include flex-align(center);
      @media (max-width: $break-s) {
        margin: 0;
      }
    }
    &__user {
      flex-basis: 80%;
      @include word-wrap;
      @media (min-width: $break-s) {
        max-width: 110px;
      }
      @media (min-width: $break-m) {
        max-width: 150px;
      }
      @media (min-width: $break-m) {
        max-width: 160px;
      }
      @media (max-width: $break-s) {
        display: none;
      }
      &:hover {
        color: $color-accent-light;
      }
    }
    &__icon {
      margin: 2px 10px 2px 0;
      height: 30px;
      width: 30px;
      box-sizing: border-box;
      img {
        margin: auto;
        display: block;
        height: 20px;
        width: auto;
        box-sizing: border-box;
      }
    }
    &__form {
      width: 100%;
    }
    &__input {
      width: 85%;
    }
  }
}

.project {
  max-width: 100%;
  margin: 10px 0;
  @media (max-width: $break-s) {
    width: 100%;
    background-color: $white;
  }
  &__empty {
    color: $white;
  }
  &__container {
    @media (max-width: $break-s) {
      @include flexbox(row, wrap, center);
    }
  }
  &__link {
    margin: 20px 0;
    display: block;
    color: $white;
    text-align: left;
    @include headerfont($font-h, 1.1em, 300);
    cursor: pointer;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: $break-s) {
      color: $color-secondary;
      border: 1px solid $color-secondary;
      padding: 5px 5px;
      font-size: 0.9em;
      font-family: $font-p;
      width: fit-content;
      display: inline-block;
      margin: 5px 2px;
    }
    &:hover {
      color: $color-bg;
    }
    &--new {
      @extend .project__link;
      color: $color-accent;
      font-size: 1em;
      @media (max-width: $break-s) {
        margin: 5px auto 0;
        display: block;
        color: $color-grayDark;
        border: 1px dashed $color-grayLight;
      }
    }
  }
  a:hover {
    color: $color-accent-light;
    background-color: transparent;
  }
}

// Right Bar
.contentInfo {
  width: 100%;
  position: relative;
  background-color: white;
  @media (max-width: $break-s) {
    margin-top: 40px;
  }
  &__title {
    @extend .bioInfo__title;
    font-size: 1.1em;
    margin: 0;
  }
  &__wrap {
    border-bottom: 2px solid $color-grayLight;
  }
}

.about {
  @include contentInfoWrap;
  @include word-wrap;
  textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 5px;
    font-size: 0.8em;
    @media (min-width: $break-m) {
      max-width: 555px;
    }
    @media (min-width: $break-l) {
      max-width: 672px;
    }
    @media (min-width: $break-xl) {
      max-width: 815px;
    }
    @media (max-width: $break-m) {
      height: 200px;
    }
  }
  &__empty {
    width: 100%;
    @include flexbox(row, wrap, center);
    @include flex-align(center);
    img {
      max-width: 500px;
      height: auto;
      width: 100%;
    }
    &__prompt {
      max-width: 200px;
      margin-left: 20px;
      span {
        display: block;
        font-size: 1em;
        color: $color-grayDark;
        margin-bottom: 15px;
      }
      a {
        border: 1px solid $color-accent;
        margin: 10px 0 0;
        padding: 3px 5px;
        font-size: 0.8em;
        &:hover {
          background-color: $color-accent;
          color: $white;
        }
      }
      @media (max-width: $break-l) {
        margin-left: 0;
        margin-top: 20px;
        text-align: center;
      }
    }
  }
}

.judge {
  @extend .about;
  input {
    width: 100%;
    margin-bottom: 5px;
    @media (min-width: $break-m) {
      max-width: 555px;
    }
    @media (min-width: $break-l) {
      max-width: 672px;
    }
    @media (min-width: $break-xl) {
      max-width: 815px;
    }
  }
  &__empty {
    @extend .about__empty;
    &__prompt {
      @extend .about__empty__prompt;
    }
  }
}

.experience {
  @include contentInfoWrap;
  &__fields {
    margin-top: 0.2em;
    &__date-wrap {
      @include flexbox(row, nowrap, flex-start);
      @media (max-width: $break-m) {
        @include flex-direction(column);
      }
    }
    &--end-date {
      @media (min-width: $break-m) {
        margin-left: 10px;
      }
    }
  }
  &__empty {
    margin-left: 10px;
    &__prompt {
      color: $color-grayDark;
      font-style: italic;
    }
    &__add {
      margin-top: 10px;
      padding: 3px 5px;
      border: 1px solid $color-accent;
      font-size: 0.8em;
      display: inline-block;
      &:hover {
        background-color: $color-accent;
        color: $white;
      }
    }
  }
  &__edit {
    @extend .userProfile__edit;
    height: fit-content;
    margin-top: -15px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  &__sect {
    padding: 20px 5px 2em 20px;
    border-left: 1px dashed $color-grayLight;
    height: fit-content;
    &__editing {
      @extend .experience__sect;
      border-color: $color-secondary;
      border-width: 2px;
      border-left-style: solid;
    }
    &__editTitle {
      @include flexbox-sb;
      @include flex-align(flex-start);
      margin: 2em 0 0 -20px;
      h4 {
        color: $color-primary;
        border-bottom: 2px solid $color-secondary;
        padding: 0 35px 5px 20px;
        font-family: $font-p;
      }
    }
    &__hexagon {
      left: -25px;
      position: relative;
    }
    &__date {
      margin: -15px 0 3px;
      color: $color-grayDark;
      letter-spacing: 1.5px;
    }
    &__company {
      margin: 0 0 5px;
      font-family: $font-h;
    }
    &__title {
      margin: 0;
      font-weight: 700;
      font-family: $font-h;
    }
    &__descript {
      margin: 0;
      color: $color-graySubDark;
    }
    &__label {
      margin-top: 20px;
      margin-bottom: 10px;
      display: inline-block;
    }
    &__input {
      width: 100%;
      margin-top: 3px;
      margin-bottom: 3px;
      font-size: 0.8em;
      @include border-box;
      &__end-disabled {
        color: transparent !important;
        background: repeating-linear-gradient(
          45deg,
          $color-grayLight,
          $color-grayLight 10px,
          $color-bg 10px,
          $color-bg 20px
        );
        &:before {
          content: 'Present';
          color: black;
        }
      }
    }
    textarea {
      width: 100% !important;
      height: 150px;
      font-size: 0.8em;
      margin-top: 5px;
    }
  }
  &__enddate {
    width: 100%;
    @include flexbox-sb;
    @include flex-align(center);
    &__present {
      &__wrap {
        width: fit-content;
        right: 0;
        display: block;
        margin-top: 5px;
      }
      &__box {
        display: none;
        &:checked ~ .experience__enddate__present__label {
          font-size: 1em;
          .experience__enddate__present__label__check {
            display: none;
          }
          &:before {
            content: '☑';
            font-size: 1.6em;
          }
        }
      }
      &__label {
        display: inline-block;
        cursor: pointer;
        color: $color-graySubDark;
        padding: 0 3px;
        font-size: 0.8em;
        min-width: 175px;
        &__check {
          font-size: 2em;
        }
        &:hover {
          background-color: $color-bg;
        }
      }
    }
  }
  &__delete {
    @include flexbox(row, nowrap, flex-end);
    @include flex-align(flex-end);
    width: 100%;
    &-btn {
      border: none;
      color: $color-grayDark;
    }
  }
}
