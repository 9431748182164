@import '../../../css/utilities/variables';
.slick {
  &-arrow {
    height: 100%;
    width: 3rem;
    z-index: 9;
    &::before {
      display: inline-block;
      height: 20px;
      width: 20px;
      border-top: 2px solid black;
      border-right: 2px solid black;
      content: '';
    }
    @media screen and (min-width: $break-m) {
      background: rgba(0, 0, 0, 0.15) !important;
    }
  }
  &-prev {
    left: 0 !important;
    &::before {
      transform: rotate(-135deg);
    }
  }
  &-next {
    right: 0 !important;
    &::before {
      transform: rotate(45deg);
    }
  }
  &-disabled {
    display: none !important;
  }
}
