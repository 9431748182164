@import "../../../css/utilities/_mixin.scss";
@import "../../../css/utilities/_variables.scss";

.custom-modal {
  text-align: center;
  width: 550px;
  max-width: 100%;
  h3 {
    font-family: $font-p;
    font-weight: $font-p-bold;
    margin-bottom: 1em;
    font-size: 1.3em;
  }
  &__message {
    font-size: 1.1em;
    margin-bottom: 1em;
  }
  &__hint {
    color: $color-graySubDark;
    font-style: italic;
    margin-bottom: 1em;
  }
  &__logo {
    width: 75px;
    height: 75px
  }
  .btn-first-choice {
    padding: 10px;
    background-color: $color-accent-light;
    color: $white;
    min-width: 100px;
  }
  .btn-second-choice {
    padding: 10px;
    border: 1px solid $color-grayDark;
    color: $color-grayDark;
    min-width: 100px;
    margin-right: 20px;
  }
  .btn-cancel {
    color: $color-grayDark;
  }
}
