/* =====================================
LAYOUT
===================================== */
html {
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  position: relative;
  min-height: 100%;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  margin: 0;
  @include scrollbars(0.5em, $color-grayDark, darken($color-grayLight, 3%));
}

.wrapper {
  @include display-grid;
  -ms-grid-columns: 200px 1fr;
  -ms-grid-rows: 35px auto;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 35px auto;
  grid-template-areas:
    'sideNav header'
    'sideNav mainSection'
    'sideNav footer';
  &__sideHidden {
    grid-template-areas:
      'header header'
      'mainSection mainSection';
  }
  @media (max-width: $break-m) {
    -ms-grid-columns: 1fr 250px;
    -ms-grid-rows: auto 35px;
    grid-template-columns: 1fr 0;
    grid-template-rows: auto 35px;
    grid-template-areas:
      'mainSection sideNav'
      'header sideNav'
      'footer sideNav';
    &__sideHidden {
      grid-template-areas:
        'mainSection mainSection'
        'header header';
    }
  }
}

/* ==================================
Main Section
=====================================*/
.mainSection {
  grid-area: mainSection;
  min-height: $full-height;
  border-top: 2px solid $color-bg;
  position: relative;
  @media (max-width: $break-m) {
    min-height: calc(100vh - 50px);
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
  }
  @media (max-width: $break-m) {
    border-top: 0;
  }
}


.flex-sb {
  @include flexbox-sb;
}
.title-wrap {
  @include flexbox-sb;
  @include flex-align(flex-end);
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-right: 15px;
    margin-bottom: 0;
  }
}
