@import 'css/utilities/_mixin.scss';
@import 'css/utilities/_variables.scss';

.fourOhFour {
  width: 100%;
  height: calc(100vh - 65px);
  background-image: url("/images/404_bg.png");
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;
  @include flexbox (column, nowrap, none);
  @media (max-width: $break-s) {
    height: calc(100vh - 60px);
  }
  &__joke {
    p {
      font-family: $font-h;
      font-size: 1em;
      margin-bottom: .3em;
      @media (max-width: $break-s) {
        font-size: .9em;
      }
    }
    margin: 15% 0 0 auto;
    padding: 30px;
  }
  &__breadCrumb {
    background-color: rgba(255,255,255,0.5);
    text-align: center;
    margin: 0 auto;
    padding: 10px;
    p {
      font-size: 1.2em;
    }
  }
}
