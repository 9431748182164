@import "../../css/utilities/_mixin.scss";
@import "../../css/utilities/_variables.scss";

#research-data-frame {
  height: calc(100vh - 42px);
  width: 100%;
}
.research-data {
  &__inner {
    max-width: $xs-max-width;
    width: $xs-max-width;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 0;
    @media (min-width: $break-s) {
      max-width: $s-max-width;
      width: $s-max-width;
      margin-left: auto;
      margin-right: auto;
    }
    @media (min-width: $break-m) {
      max-width: $m-max-width;
      width: $m-max-width;
      margin-left: auto;
      margin-right: auto;
    }
    @media (min-width: $break-l) {
      max-width: $l-max-width;
      width: $l-max-width;
      margin-left: auto;
      margin-right: auto;
    }
    @media (min-width: $break-xl) {
      max-width: $xl-width;
      width: 1000px;
      margin-left: auto;
      margin-right: auto;
    }
    @media (min-width: $break-xxl) {
      max-width: $max-width;
      width: 1000px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .flex-box {
    margin-bottom: 10%;
    @include flexbox(row, nowrap, space-between);
    @media (max-width: $break-l) {
      @include flexbox(column, wrap, center);
      margin-top: 2em;
    }
    @media (min-width: $break-xl) {
      @include flexbox(row, nowrap, flex-start);
    }
  }
  h1,
  h2,
  h4 {
    text-transform: uppercase;
    line-height: 1;
  }
  h2 {
    font-size: 3.2em;
    margin: 0;
    padding-bottom: 20px;
    font-weight: $font-p-bold;
    font-family: $font-p;
    @media (max-width: $break-m) {
      width: fit-content;
      font-size: 2.2em;
    }
  }
  h4 {
    font-weight: $font-p-bold;
    font-size: 1.3em;
    margin: 0 0 -20px 0;
    @media (max-width: $break-m) {
      width: fit-content;
      font-size: 1em;
      margin: 0 0 -10px 0;
    }
  }
  .table-title {
    text-align: center;
    font-weight: $font-p-bold;
    margin-top: 2em;
  }
  .note {
    margin-left: auto;
    margin-right: auto;
    font-style: italic;
    font-size: .9em;
    margin-top: 10px;
    max-width: 800px;
  }
  .table-intro {
    margin-top: 6em;
  }
  table, th, td {
    border: 1px solid $color-secondary-light;
    border-collapse: collapse;
    padding: 5px 10px;
    font-size: .9em;
  }
  th {
    font-weight: bold;
    background: $color-secondary;
    color: $white;
    max-width: 300px;
    span {
      font-weight: bold;
    }
  }
  td {
    text-align: center;
    &:first-child {
      text-align: left;
    }
  }
  table {
    margin-left: auto;
    margin-right: auto;
  }
  .color-9 {
    background: darken($color-accent, 20%);
    color: $white;
  }
  .color-8 {
    background: darken($color-accent, 15%);
    color: $white;
  }
  .color-7 {
    background: darken($color-accent, 10%);
    color: $white;
  }
  .color-6 {
    background: darken($color-accent, 5%);
    color: $white;
  }
  .color-5 {
    background: $color-accent;
  }
  .color-4 {
    background: lighten($color-accent, 5%);
  }
  .color-3 {
    background: lighten($color-accent, 10%);
  }
  .color-2 {
    background: lighten($color-accent, 15%);
  }
  .color-1 {
    background: lighten($color-accent, 20%);
  }
  .color-0 {
    background: lighten($color-accent, 30%);
  }

}
