@import 'css/utilities/_mixin.scss';
@import 'css/utilities/_variables.scss';

.inviteTeam {
  max-height: 80vh;
  @include modal-wrap (500px);
  max-width: 700px;
  h3 {
    margin-bottom: 2em;
    span {
      font-style: italic;
      color: $color-grayDark;
      font-size: .8em;
      display: block;
      margin-top: 5px;
    }
  }
  &__btn {
    @include right-align-block;
    @include btn-send;
    @media (max-width: $break-m) {
      margin-bottom: 10px;
    }
    &__nudge {
      border: 1px solid $color-accent;
      color: $color-accent;
      cursor: pointer;
      margin: 0;
      &:hover,
      &.hover {
        color: $white;
        background: $color-accent;
      }
      &:active
      &.active {
        background: $color-accent-dark;
        color: $white;
      }
    }
  }
  &__pendingTitle {
    margin-top: 3em;
    line-height: 100%;
  }
}

.inviteForm {
  @include flexbox (row, wrap, space-between);
  @include flex-align(center);
  border-bottom: 2px solid $color-grayLight;
  padding-bottom: 2em;
  span {
    color: $color-primary;
    font-style: italic;
    margin-top: 5px;
    display: block;
    min-width: 100%;
  }
  &__wrap {
    @include flexbox (row, wrap, flex-start);
    @include flex-align(center);
    width: 80%;
    @media (max-width: $break-m) {
      @include flex-justify(space-between);
      min-width: 100%;
    }
    input {
      min-width: 300px;
      @media (max-width: $break-l) {
        min-width: 397px;
      }
      @media (max-width: $break-m) {
        min-width: 100%;
        max-width: 100%
      }
    }
    select {
      margin: 0;
      min-width: 200px;
      @media (max-width: $break-m) {
        min-width: 100%;
        max-width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  &__input {
    width: 100%;
    max-width: 300px;
    margin: 5px 0;
    &:first-child {
      margin-right: 10px;
    }
    @media (max-width: $break-m) {
      max-width: 400px;
      &:first-child {
        margin-right: 0;
      }
    }
  }
  div {
    @media (max-width: $break-m) {
      width: 100%;
    }
  }
  p {
    overflow-wrap: break-word;
  }
  a {
    color: $color-bright;
    width: 60px;
    margin-right: 10px;
  }
}

.pending {
  margin: 10px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid $color-bg;
  &__info {
    @include flexbox (row, wrap, flex-start);
    @include flex-align(center);
    width: 70%;
    select {
      min-width: 200px;
    }
  }
  &--email {
    font-weight: $font-p-bold;
    margin-bottom: 0;
    min-width: 55%;
  }
  &--btn-wrap {
    @media (max-width: $break-m){
      @include flexbox (row, wrap, space-between);
      margin: 10px 0;
    }
  }
}
