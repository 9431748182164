@media print {

$font-h: sans-serif;
$font-p: sans-serif;
.sideNav, header, footer {
  display: none;
}
.wrapper {
  display: block;
}
//Project Profile
.p-profile {
  .menu, .edit-pen__btn, .edit-pen__topic, .details__permission, .btn-wrap {
    display: none !important;
  }
  .overview {
    width: 100%;
  }
  .ql-editor {
    padding: 2px;
  }
}

//challenges
.side-nav, .challengeWrap__breadCrumb, .topic-bar {
  display: none;
}
.challengeWrap {
  max-width: 100%;
}
.challenge {
  margin: 0;
}
.workshop__instr {
  margin: 10px 0;
}
.ql-editor {
  padding: 2px;
}

// Person Profile 
.userProfile {
  .visibility, .edit-pen__btn, .plus-sign__btn {
    display: none !important;;
  }
}

.ePage {
  button, .btn-wrap, .btn-main {
    display: none;
  }
  &__back, .ePage__back-2 {
    background-color: $white !important;
  }
  &__title, .ePage__title__name {
    color: $color-secondary !important;
  }
  &__infoBanner{
    &__sect {
      padding: 0 2px !important;
      img {
        height: 15px
      }
    }
    &__info {
      margin-left: 5px;
    }
  }
}
}