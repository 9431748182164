@import '../../../../css/utilities/mixin';
@import '../../../../css/utilities/variables';

.header {
  margin: 0;
  z-index: 10;
  grid-area: header;
}

.topNav {
  @include flexbox-sb;
  @include flex-align(center);
  background-color: transparent;
  @media (max-width: $break-m) {
    @include flex-direction(row-reverse);
  }

  a {
    &:focus {
      border-bottom: 0 !important;
    }
  }

  li {
    display: inline-block;
    padding: 0 5px;
    margin: 0 5px;
    box-sizing: border-box;
    @media (min-width: $break-m) and (max-width: $break-l) {
      padding: 0 2px;
      margin: 0 2px;
    }
    a {
      color: $color-secondary-light;
      img {
        display: inline-block;
      }
    }
  }
  &__icon {
    width: auto;
    max-height: 16px;
    margin: 0 3px;
    @media (max-width: $break-m) {
      width: auto;
      height: 20px;
      margin: 0 auto !important;
    }
    &__new {
      height: 16px;
      width: auto;
      @media (max-width: $break-m) {
        margin: 0 auto !important;
        height: 20px;
      }
    }
    &__avatar-wrap {
      display: inline-block;
      margin: 0 3px;
      @media (max-width: $break-m) {
        margin: 0 auto !important;
      }
      img {
        width: auto;
        height: 16px;
        object-fit: cover;
        @media (max-width: $break-m) {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
