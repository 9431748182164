@import 'css/utilities/_mixin.scss';
@import 'css/utilities/_variables.scss';

.createHub {
  margin: 20px;
  &__title {
    color: $color-primary;
    margin-bottom: 30px;
    text-transform: uppercase;
  }
  &__side {
    margin: -25px 0 3em;
    color:$color-secondary;
  }
  &__note {
    color:$color-grayDark;
    margin-top: -15px;
    font-style: italic;
  }
  &__question {
    margin: 10px 0 25px;
    @include flexbox (row, wrap, flex-start);
    align-items: flex-start;
  }
  &__label {
    width: 300px;
    display: inline-block;
    text-transform: uppercase;
    @media(max-width: $break-l) {
      margin-bottom: 10px;
    }
  }
  &__field {
    width: 350px;
    display: inline-block;
  }
  input {
    margin: 0;
  }
  &__send {
    padding: 10px 12px;
    border-color: $color-accent;
    color: $color-accent;
    font-weight: $font-p-bold;
  }
  .location__wrap {
    input {
      @extend .createHub__field;
    }
  }
  &__pageIndex {
    color: $color-grayDark;
    margin-right: 15px;
  }
  &__skip {
    color: $color-grayDark;
    margin-right: 15px;
  }
  .logo__wrap {
    display: inline-block;
    img {
      display: block;
      width: 100px;
      height: 100px;
      border: 1px solid $color-grayLight;
    }
    button {
      display: block;
      margin: 3px 0 0;
      width: 100px;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__description {
    height: 100px;
    width: 500px;
    resize: none;
  }
  &__pending {
    border-top: 2px solid $color-grayDark;
    margin: 20px;
    padding: 25px 0;
    &__head {
      color: $color-secondary;
    }
    &__wrap {
      border-bottom: 1px solid $color-grayLight;
      padding: 15px 0;
      @include flexbox( row, wrap, space-between);
    }
    &__email {
      width: 400px;
    }
    &__delete {
      color: $color-bright;
    }
    &__nudge {
      color: $color-accent;
    }
    button {
      border: 0;
    }
  }
}
