/* =====================================
BASE
===================================== */
//Default styles that will go on anywhere and everypage
//No class or id selectors
html * {
  font-family: $font-p;
}

h1:not(.MuiTypography-root) {
  @include headerfont($font-h, 2em, $font-h-normal);
  letter-spacing: 1px;
  line-height: 1.4;
  font-style: normal;
  margin: 0 0 0.4em;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  color: $color-secondary;
  @media (max-width: $break-xs) {
    font-size: 3.2em;
  }
}

h2:not(.MuiTypography-root) {
  @include headerfont($font-h, 1.5em, $font-h-normal);
  letter-spacing: 0.7px;
  line-height: 1.4;
  font-style: normal;
  margin: 0 0 0.4em;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  color: $color-secondary;
}

h3:not(.MuiTypography-root) {
  @include headerfont($font-h, 1.2em, $font-h-normal);
  letter-spacing: 0.7px;
  line-height: 1.4;
  font-style: normal;
  margin: 0 0 0.4em;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  color: $color-secondary;
}

h4:not(.MuiTypography-root) {
  @include headerfont($font-h, 1.1em, $font-h-normal);
  letter-spacing: 0.7px;
  font-style: normal;
  margin: 0 0 0.4em;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  color: $color-secondary;
}
h6:not(.MuiTypography-root) {
  //for section titles
  @include headerfont($font-h, 1.1em, $font-h-normal);
  letter-spacing: 0.7px;
  display: block;
  text-transform: uppercase;
  color: $color-grayDark;
  font-size: 0.9em;
  margin: 0;
}

p {
  font-family: $font-p;
  margin: 0 0 1em;
  font-weight: $font-p-normal;
  line-height: 1.4;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 1em;
  vertical-align: baseline;
  white-space: pre-wrap;
}
div {
  line-height: 1.4;
}
ul,
ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

//LINKS AND BUTTONS
a {
  &:not(.MuiMenuItem-root):not(.MuiLink-root) {
    color: $color-accent;
    text-decoration: none;
    font-family: $font-p;
    cursor: pointer;
    line-height: 110%;
  }
  &:not(.MuiMenuItem-root):not(.MuiLink-root)  {
    &:hover {
      color: $color-secondary;
    }
    &:active {
      border: none;
      background-color: none;
      outline: none;
    }
    &:focus {
      border-bottom: 2px solid $color-primary-light;
      outline: none;
    }
  }
}

button {
  &:not(.MuiButtonBase-root, .MuiIconButton-root, .MuiButton-root, .MuiCardActionArea-root) {
    background-color: transparent;
    border: 0.5px solid $color-grayLight;
    letter-spacing: 0.7px;
    cursor: pointer;
    @include border-radius(0);
    box-sizing: border-box;
    font-size: 0.8em;
    border-radius: 3px;
    &:active {
      background-color: transparent;
      color: $white;
    }
    &:focus {
      border-bottom: 2px solid $color-primary-light;
      outline: none;
    }
    &:disabled {
      color: $color-grayDark;
      background-color: $color-bg;
      padding: 3px 6px;
      border-color: $color-grayLight;
      cursor: not-allowed;
      &:hover {
        color: $color-grayDark;
        background-color: $color-bg;
        border-color: $color-grayLight;
      }
    }
  }

  &.btn-cancel {
    &:hover {
      color: $color-grayDark !important;
    }
  }

  &:not(.MuiButtonBase-root) {
    &:hover {
      color: $white;
    }
  }
}

//FORMS AND INPUT

input {
  border: 0.5px solid $color-grayLight;
  font-family: $font-p;
  font-weight: 400;
  letter-spacing: 1px;
  background-color: $white;
  color: black;
  cursor: text;
  line-height: 1.5em;
  padding: 10px 6px;
  @include border-box;
  @-moz-document url-prefix() {
    line-height: 1.5em;
  }
  // TODO after replace all traditional text fields to mui text box then remove it
  &:not(.MuiInputBase-input) {
    &:focus {
      outline-color: none;
      outline: 0;
      border-bottom: 3px solid $color-primary;
    }
    &::placeholder {
      color: darken($color-grayDark, 20%);
    }
    &:disabled {
      background-color: $color-grayLight !important;
      color: $color-grayDark;
      cursor: not-allowed;
    }
  }
}
input[type='checkbox'] {
  //visibility: hidden;
  @include border-box;
  cursor: pointer;
  &:focus {
    outline: 0;
    border-bottom: 3px solid $color-primary;
  }
}
input[type='radio']:after {
  width: 17px;
  height: 17px;
  border-radius: 15px;
  top: -2px;
  left: -2.5px;
  position: relative;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid $color-bg;
  background: $white;
  cursor: pointer;
}

input[type='radio']:checked:after {
  width: 17px;
  height: 17px;
  border-radius: 15px;
  top: -2px;
  left: -2.5px;
  position: relative;
  background-color: $color-accent-light;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid $color-bg;
  cursor: pointer;
}
input[type='date'] {
  color: black;
  cursor: pointer;
}
input[type='submit'] {
  background-color: $color-primary;
  color: $white;
  @include headerfont($font-h, 0.9em, 500);
  border: 0;
  letter-spacing: 1.3px;
  cursor: pointer;
  @include border-radius(0);
  padding: 2px 8px;
  margin-left: 4px;
  &:hover {
    background-color: $color-primary-light;
  }
  &:active {
    background-color: $color-secondary-light;
  }
  &:focus {
    border-bottom: 2px solid $color-primary;
    outline: none;
  }
}
input:disabled {
  &:not(.MuiInputBase-input) {
    background-color: $color-grayLight;
    cursor: not-allowed;
  }
}
select {
  font-family: $font-p;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1.5em;
  overflow: hidden;
  border: 0.5px solid $color-grayLight;
  @include border-radius(0);
  -webkit-appearance: none;
  cursor: pointer;
  color: black;
  padding: 10px 20px 10px 6px;
  margin: 5px auto;
  @include border-box;
  background: #fff url(/images/top_dropdown.png) no-repeat 95% center;
  background-size: 10px 5px;
  color: black;
  &:disabled {
    background-image: none;
  }
  &::-ms-expand {
    display: none; /* hide the default arrow in ie10 and ie11 */
  }
  &:focus {
    outline: 0;
    border-bottom: 3px solid $color-primary;
  }
}
textarea {
  &:not(.MuiInputBase-input) {
    background-color: $white;
    color: black;
    padding: 5px;
    font-family: $font-p;
    width: 98%;
    outline: none;
    overflow: auto;
    cursor: text;
    white-space: pre-line;
    border: 0.5px solid $color-grayLight;
    @include border-box;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 1.5em;
    margin: 5px auto;
    padding: 10px 6px;
    @include border-box;
    &::placeholder {
      color: darken($color-grayDark, 20%);
    }
    &:focus {
      border-bottom: 3px solid $color-primary;
    }
    &:disabled {
      background-color: $color-grayLight;
      cursor: not-allowed;
    }
  }
}

label:not(.MuiInputLabel-root) {
  margin-top: 8px;
  margin-bottom: 4px;
  line-height: 110%;
  cursor: pointer;
}

@supports (-webkit-overflow-scrolling: touch) {
  input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  select:focus,
  textarea {
    font-size: 16px !important;
  }
}

input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}

hr {
  margin: 20px 0;
  color: $color-grayLight;
  width: 100%;
}

img {
  font-size: 0.5em;
  color: $color-secondary;
}
