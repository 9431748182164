@import './css/utilities.scss';
@import './FormInputs/styles/quill.snow.css';
@import './shared/modal/modal.scss';

html,
body {
  overflow-x: hidden;
}

.react-confirm-alert-overlay {
  z-index: 999 !important;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transform: translateZ(1000px);
}
.react-confirm-alert-body {
  border-radius: 0 !important;
  font-family: $font-p !important;
  h1 {
    text-transform: uppercase;
  }
}
.react-confirm-alert-button-group {
  justify-content: flex-end !important;
  button {
    border-radius: 3px !important;
    &:first-child {
      background: $white;
      border: 1px solid $color-secondary;
      color: $color-secondary;
    }
    &:last-child {
      background: $color-accent;
      border: 1px solid $color-accent;
    }
  }
}

.empty-layout {
  overflow-x: hidden;
  min-height: 100vh;
  width: 100vw;
  background: #efefef;
}
