@import '../../css/utilities/_mixin.scss';
@import '../../css/utilities/_variables.scss';

.multi_email {
  border-radius: 0.3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .input {
    width: 100%;
    height: 45px;
    padding: 0 1rem;
    margin-top: 1rem;
    box-sizing: border-box;
    font: inherit;
    border-radius: 0.2rem;
    border: 2px solid #d4d5d6;
    color: #565656;
    background-color: white;
    box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
    -webkit-appearance: none;
  }

  .input:focus {
    border-color: cornflowerblue;
    outline: none;
  }

  .input.has-error {
    border-color: tomato;
  }

  .error {
    margin: 0;
    font-size: 90%;
    color: tomato;
  }

  .tag-item {
    background-color: $color-tertiary;
    color: $white;
    display: inline-block;
    font-size: 14px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
  }

  .tag-item > .button {
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.multi_email--reverse {
  flex-direction: column-reverse;
}
