/* =====================================
STATE
===================================== */

.isHidden {
	display: none !important;
}


//Errors

.alert {
  text-align: center;
  width: 100%;
  &__info {
    background-color: $color-secondary;
  }
  &__danger {
    background-color: $color-bright;
  }
}

.errors {
  color: $color-bright;
  font-size: .8em;
  margin-bottom: 5px;
  margin-top: 3px;
  @include right-align-block;
  text-align: right;
  &:before {
    content: "*"
  }
}

//disabled

.btn-disabled {
	background-color: $color-grayDark!important;
	color: $color-grayLight !important;
	&:hover {
		background-color: $color-grayDark !important;
		color: $color-grayLight !important;
	}
	&:active {
		background-color: $color-grayDark;
		color: $color-grayLight;
	}
}


//required for forms

.required:after {
	content:"*";
	color: $color-accent;
	text-transform: lowercase;
	font-size: 12px;
	padding-left: 4px;
}

.required-prompt {
	// content:"*required";
	color: $color-accent;
	text-transform: lowercase;
	font-size: 12px;
	display: block;
	text-align: right;
	margin-bottom: 10px;
}

.optional:after {
	content: "(optional)";
	color: $color-grayDark;
	font-style: italic;
	font-size: 12px;
	font-family: $font-p;
	text-transform: lowercase;
	top: 0;
}
