/* ==================================
Unsupported
=====================================*/
.unsupported-browser {
  margin: 0 10%;
  padding-top: 180px;
  background: url('/images/no_permission_robot.png') no-repeat center 30px;
  background-size: 200px auto;
  h1 {
    text-transform: uppercase;
    text-align: center;
  }
  h2 {
    font-family: $font-p;
    font-size: 1.4em;
  }
  .browser-wrap {
    text-align: center;
  }
  .link-block {
    padding-top: 110px;
    display: inline-block;
    font-weight: $font-p-bold;
    margin-top: 20px;
    @media (max-width: $break-s) {
      display: block;
    }
  }
  .firefox {
    background: url('https://www.mozilla.org/media/protocol/img/logos/firefox/browser/logo-md.f0603b4c28b4.png')
      no-repeat center top;
    margin-right: 40px;
    @media (max-width: $break-s) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  .chrome {
    background: url('https://www.google.com/chrome/static/images/chrome-logo.svg')
      no-repeat center top;
    background-size: 100px auto;
    margin-right: 40px;
    @media (max-width: $break-s) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  .safari {
    background: url('https://km.support.apple.com/kb/image.jsp?productid=PL165&size=120x120')
      no-repeat center top;
    background-size: 100px auto;
  }
}
