@import '../../css/utilities/_mixin.scss';
@import '../../css/utilities/_variables.scss';

.modal {
  max-width: 100%;
  max-height: 100%;
  form {
    cursor: default;
  }
}
//for image uploader only
.image-upload {
  display: inline-block;
  padding: 6px 12px;
  &__img-wrapper {
    margin: 0 auto;
    overflow: hidden;
    border: 3px solid $white;
    img {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      display: block;
      margin: auto auto;
      display: block;
    }
  }
  &__upload-btn {
    border: 1px solid $color-secondary;
    padding: 5px 8px;
    text-align: center;
    margin: 10px auto 0px;
    display: block;
    max-width: 150px;
    cursor: pointer !important;
    &:hover {
      border-width: 1px 1px 4px 1px;
    }
    &:active {
      background-color: $color-accent;
    }
  }
  &__save-btn {
    @include right-align-block;
    margin-top: 2em;
    background-color: $color-primary;
    color: $color-secondary;
    padding: 10px 15px;
    border: 0;
    &:hover {
      border: 1px solid $color-primary;
      background-color: white;
    }
  }
}

input[type='file'] {
  display: none;
}
