@import '../../../css/utilities/_mixin.scss';
@import '../../../css/utilities/_variables.scss';

.forgot-pass {
  @include vertical-hor-align;

  &__cont {
    padding: 20px;
    max-width: 400px;
    width: 400px;

    .app-logo {
      width: 200px;

      &__container {
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
      }
    }
  }
  &__input {
    margin: 10px 0;
    width: 100%;
  }
  &__btn {
    margin: 0;
    &:hover {
      background-color: $color-secondary;
      color: $white;
    }
  }
  &__links {
    margin-top: 25px;
    p {
      display: inline;
      color: $color-grayDark;
      font-size: 0.8em;
    }
    @media (max-width: $break-m) {
      width: 90%;
    }
  }
}
