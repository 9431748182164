@import '../../../../css/utilities/mixin';
@import '../../../../css/utilities/variables';

// TODO
// 1) Redesign Mobile Menu ( Team Invite Member and Change Default Project Modals )
// 2) Migrate Modal window to mui modal popup
// 3) Please get rid of this style file

//BUTTONS
.sideNav {
  &__content {
    margin-top: 10px;
  }
}

//More (MOBILE ONLY)
.sideNav {
  .more {
    border: 0;
    @media (min-width: $break-m) {
      display: none;
    }
    &__label {
      color: $white;
      padding: 5px;
      margin-bottom: 0.5em;
      display: block;
      width: 100%;
      text-align: center;
      font-family: $font-h;
      font-size: 0.9em;
      &:after {
        padding: 10px;
        content: '';
        background: url('/images/icon_down_white.png') no-repeat center center;
        background-size: 10px;
      }
    }
    &__checkbox {
      display: none;
      &:checked ~ .more__links {
        display: block;
      }
      &:checked ~ .more__label:after {
        background: url('/images/icon_up_white.png') no-repeat center center;
        background-size: 10px;
      }
    }
    &__links {
      display: none;
    }
    &__btn-link {
      display: block;
      color: $white;
      font-size: 0.8em;
      width: 100%;
      padding: 8px 8px;
      border: 0;
      letter-spacing: 0.7px;
      text-align: left;
    }
    .language-picker {
      &__more {
        color: $white;
        width: 100%;
        display: block;
        font-size: 0.8em;
        letter-spacing: 0.7px;
        padding: 8px;
        cursor: pointer;
        &:after {
          content: '';
          background: url('/images/icon_world_white.png') no-repeat center right;
          background-size: 15px 15px;
          padding: 8px 0 8px 16px;
          margin-left: 10px;
        }
        &:hover {
          background-color: $color-secondary;
          color: $white;
          ~ .language-picker__side {
            display: block;
          }
        }
      }
      &__side {
        display: none;
        position: inline;
        &:hover {
          display: block;
        }
      }
      &__link {
        color: $white;
        @include flexbox(row, nowrap, center);
        padding: 8px 18px;
        width: 100%;
        border-radius: 0;
        border: 0;
        position: relative;
        &:hover {
          background-color: $color-secondary;
          color: $white;
        }
        &--selected {
          @extend .language-picker__link;
          position: relative;
          background-color: darken($color-secondary-dark, 10%);
          color: $color-bg;
          &:before {
            content: '';
            background: url('/images/icon_check_white.png') no-repeat center
            left;
            background-size: 10px 10px;
            padding: 5px;
            position: absolute;
            left: 20px;
            top: 12px;
          }
        }
      }
    }
  }
}
//SELECT PROJECT
.selectProj {
  @media (max-width: $break-m) {
    max-width: 100%;
    width: 80vw;
  }
  @media (max-width: $break-s) {
    max-width: 100%;
    width: 100vw;
  }
  &__head {
    @include flexbox-sb;
    @include flex-align(flex-start);
  }
  h3 {
    text-transform: uppercase;
    margin-top: 5px;
    color: $color-accent;
  }
  ul {
    margin: 20px 0;
    &:hover > li:not(:hover) {
      opacity: 0.5;
      background-color: $white !important;
      color: $color-secondary !important;
    }
  }
  li {
    padding: 10px 0 10px 20px;
    width: 400px;
    cursor: pointer;
    @media (max-width: $break-m) {
      width: 100%;
    }
    &:hover {
      background-color: $color-accent-light;
      color: $white;
    }
  }
  &__defaultProj {
    background-color: $color-accent-light;
    color: $white;
  }
  &__notes {
    max-width: 400px;
    margin: 0 0 2rem;
    color: $color-graySubDark;
  }
  &__current {
    max-width: 400px;
    span {
      text-transform: uppercase;
    }
  }
}

.transition-enter {
  opacity: 0.01;
}

.transition-enter.transition-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.transition-exit {
  opacity: 1;
}

.transition-exit.transition-exit-active {
  opacity: 0.01;
  transition: opacity 150ms ease-in;
}
